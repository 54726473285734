import React from "react";
import { Link } from "react-router-dom";
import Welcome from "./Welcome";

function NotFound() {
  return (
    <>
      <Welcome />
      <section id="not-found">
        <div className="overlay" />
        <div className="hero-container">
          <div className="environment"></div>
          <h6 className="hero glitch layers mega" data-text="404">
            <span>404</span>
          </h6>
        </div>
        <h4 className="glow">
          Whatever you're looking for... <br />
          It's not here!
        </h4>
        <Link to={{ pathname: "/", state: "flushDeal" }}>
          Go Home
        </Link>
      </section>
    </>
  );
}

export default NotFound;

import * as React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import MenuIcon from "@mui/icons-material/Menu";

export default function TemporaryDrawer({
  connection,
  disconnect,
  showAddress,
  addressSplit,
}) {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const StyledIcon = styled(ListItemIcon)`
    color: #00cccc;
  `;

  const openModal = async () => {
    await setState({ ...state, right: false });
    connection();
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      className="styled-box"
    >
      <List>
        {["mint", "faq", "rules", "roadmap"].map((text) => (
          <Link to={{ pathname: "/#/" + text, state: "flushDeal" }}>
            <ListItem button key={text}>
              <StyledIcon
              color="#00cccc">
                <DoubleArrowIcon />
              </StyledIcon>
              <ListItemText
                primary={text}
                color="#00cccc"
                className="list-item-text"
              ></ListItemText>
            </ListItem>
          </Link>
        ))}
      </List>
    </Box>
  );

  const StyledDrawer = styled(Drawer)`
    z-index: 9999999999 !important;
    display: flex;
    flex-direction: column;
    svg{
        fill: #00cccc;
    }
    & > * {
      justify-content: space-between;
      text-transform: uppercase;
      color: #00cccc !important;
      fill: #00cccc !important;
      background-color: rgba(0, 0, 0, 0.8) !important;
    }
  `;

  return (
    <div>
      <React.Fragment key={"right"}>
        <Button className="menu-button" onClick={toggleDrawer("right", true)}>
          <MenuIcon />
        </Button>
        <StyledDrawer
          anchor={"right"}
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
        >
          <div>{list("right")}</div>
          <div>
            {showAddress ? (
              <button className="menu-bottom-button" onClick={disconnect}>
                {addressSplit}
              </button>
            ) : (
              <button
                className="menu-bottom-button"
                onClick={() => openModal()}
              >
                Connect Wallet
              </button>
            )}
            <Link to={{ pathname: "/#/play", state: "flushDeal" }}>
              <div className="menu-bottom-button">Play</div>
            </Link>
          </div>
        </StyledDrawer>
      </React.Fragment>
    </div>
  );
}

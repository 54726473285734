import React, { Suspense, lazy } from "react";
import "./App.scss";
import "./glitch.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Container from "./components/Container";
import ButtonAppBar from "./components/AppBar";
import PageLoader from "./components/PageLoader";
import NotFound from "./components/NotFound";


const Home = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./components/Home")), 3500);
  });
});
const Mint = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./components/Mint")), 700);
  });
});
const Rules = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./components/Rules")), 700);
  });
});
const Roadmap = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./components/Roadmap")), 700);
  });
});
const About = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./components/About")), 700);
  });
});

function App() {

  return (
    <Router>
      <ButtonAppBar />
      <Suspense fallback={<PageLoader />}>
        <Container>
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="/#/mint" exact>
              <Mint />
            </Route>
            <Route path="/#/rules" exact>
              <Rules />
            </Route>
            <Route path="/#/faq" exact>
              <About />
            </Route>
            <Route path="/#/roadmap" exact>
              <Roadmap />
            </Route>
            <Route component={NotFound} />
          </Switch>
        </Container>
      </Suspense>
    </Router>
  );
}

export default App;

import React from 'react'

import altBg from "../assets/images/animated-bg.gif";


function Welcome() {
  return (
    <section id="welcome">
        <div className="upper-shadow"/>
        <img src={altBg} alt="Avatars" />
        <div className="overlay" />
      </section>
  )
}

export default Welcome
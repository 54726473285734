import React from "react";

import animated from "../assets/images/animation/loader.gif";
import Welcome from "./Welcome";

function PageLoader() {
  return (
    <>
      <Welcome />
      <section id="loader-container">
        <img src={animated} id="loader" alt="" />
        <div id="platform" />
        <div id="building" />
        <div id="city" />
      </section>
    </>
  );
}

export default PageLoader;

import React from "react";
import styled from "styled-components";

const ContainerBlock = styled.div`
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 3000px;
  margin: 0 auto;
  padding-top: 50px;
  overflow-x: hidden;
  overflow-y: scroll;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  box-sizing: border-box;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Container = ({ children }) => {
  return (
    <>
    <ContainerBlock id="Container">
      {children}</ContainerBlock>
    </>
  );
};

export default Container;

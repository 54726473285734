import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Web3 from "web3";
import Web3Modal from "web3modal";
import Drawer from "./Drawer";
import WalletConnectProvider from "@walletconnect/web3-provider";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));


let provider
let web3
let accs
let chainId

export default function ButtonAppBar() {
  const [accounts, setAccounts] = useState();
  const [addressSplit, setAddressSplit] = useState();
  const [showAddress, setShowAddress] = useState();

  const ethereumEvents = [
    "accountsChanged",
    "chainChanged",
    "connect",
    "disconnect",
  ];
  const supportedChainId = ["0x7a69", 31337, 137, 80001]
  const isSupportedChainId = (chainId) => {
    let boolean = false;
    supportedChainId.map((el) => {
      if (chainId === el) boolean = true;
    });
    return boolean;
  };

  const addressMetamaskSplitter = (value) => {
    let start = value.slice(0, 4);
    let end = value.slice(38, 42);
    let strech = start + "..." + end;
    return strech;
  };

  const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider, 
      options: {
        infuraId: process.env.REACT_APP_INFURA_ID,
        rpc: {
                137: process.env.REACT_APP_MAINNET_INFURA,
                80001: process.env.REACT_APP_TESTNET_INFURA
              } 
      },
    }
  }; 

  const web3Modal = new Web3Modal({
    disableInjectedProvider: false,
    network: "matic", // optional
    cacheProvider: false, // optional
    providerOptions, // required
    theme: "dark",
  })


  const connection = async () => {
    web3Modal.clearCachedProvider();
    provider = await web3Modal.connect();
    web3 = new Web3(provider)
    chainId = await web3.eth.getChainId()
    if(await isSupportedChainId(chainId)){
      accs = await web3.eth.getAccounts();
      setAccounts(accs[0]);
      ethereumEvents.map(async (element) => {
        await provider.on(element, async() => {
          web3 = new Web3(provider)
          chainId = await web3.eth.getChainId()
          if(await isSupportedChainId(chainId)){
            window.location.reload()
          } else {
            alert("unsupported ChainId")
            disconnect()
          }
      })
    })
    } else {
      alert("unsupported ChainId")
      disconnect()

    }
    
  };

  const disconnect = async () => {
    try {
          web3Modal.clearCachedProvider()
          web3 = null
          await setAccounts(null)
          await provider.close()
          } catch {
              await web3Modal.clearCachedProvider()
              window.location.reload();
          }
  };

  const childRef = useRef();
  const classes = useStyles();

  useEffect(() => {
    if (accounts !== null && accounts !== undefined) {
      setAddressSplit(addressMetamaskSplitter(accounts));
      console.log("address", addressSplit);
    } else {
      setAddressSplit();
    }
  }, [accounts]);

  useEffect(() => {
    if (addressSplit !== null && addressSplit !== undefined) {
      setShowAddress(true);
    } else {
      setShowAddress();
    }
  }, [addressSplit]);

  const [scrolledMenu, setScrolledMenu] = useState("");

  useEffect(() => {
    document.addEventListener("scroll", () => {
      let yScroll = window.scrollY;

      if (yScroll > 50) {
        setScrolledMenu("scrolled");
      } else {
        setScrolledMenu("");
      }
    });
  }, []);

  return (
    <div className={scrolledMenu + " bar"}>
      <section id="text-logo-container">
        <Link to={{ pathname: "/", state: "flushDeal" }}>
          <span>CRYPTOBASTARDS</span>
        </Link>
      </section>
      {/* <Router> */}
      <section id="menu-nav">
        <ul>
          <li>
            <Link to={{ pathname: "/#/mint", state: "flushDeal" }}>Mint</Link>
          </li>
          <li>
            <Link to={{ pathname: "/#/faq", state: "flushDeal" }}>FAQ</Link>
          </li>
          <li>
            <Link to={{ pathname: "/#/rules", state: "flushDeal" }}>Rules</Link>
          </li>
          <li>
            <Link to={{ pathname: "/#/roadmap", state: "flushDeal" }}>
              Roadmap
            </Link>
          </li>
          <li>
            {showAddress ? (
              <button className="connect-wallet-button active" onClick={disconnect}>
                {addressSplit}
              </button>
            ) : (
              <button className="connect-wallet-button flicker" onClick={connection}>
                Connect
              </button>
            )}
          </li>
          <li className="play-button">Play</li>
        </ul>
      </section>
      <section id="mobile-nav">
        <Drawer showAddress={showAddress} addressSplit={addressSplit} connection={connection} disconnect={disconnect}/>
      </section>
      {/* </Router> */}
    </div>
  );
}
